import App from 'next/app'
import cookie from 'cookie'
import api, { createApi } from '@/lib/api'
import { Head, NextNProgress, Page } from '@/components'
import { OrderProvider } from '@/contexts/OrderContext'
import { LocaleProvider } from '@/contexts/LocaleContext'
import { PageTransition } from 'next-page-transitions'
import parser from 'accept-language-parser'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

function parseCookies(req) {
    return cookie.parse(req ? req.headers.cookie || '' : document.cookie)
}

class MyApp extends App {
    static async getInitialProps({ Component, ctx }) {
        let pageProps = {}
        let language = parseCookies(ctx.req).es_lang

        if (!language) {
            const acceptLanguages = ctx.req?.headers['accept-language']
                ? parser.parse(ctx.req.headers['accept-language']).filter(item => item.code === 'ru' || item.code === 'en' || item.code === 'et')
                : []

            language = acceptLanguages?.length ? acceptLanguages.sort((a, b) => b - a)[0].code : 'ru'
        }

        const currentApi = createApi(
            () => parseCookies(ctx.req).es_token,
            () => parseCookies(ctx.req).es_lang,
        )
        // язык в запросах сейчас ничего не меняет, оставила на всякий случай
        const master = ctx.query?.id ? currentApi.master(ctx.query.id)({ set_default_locale: true }) : undefined

        if (Component.getInitialProps) {
            pageProps = await Component.getInitialProps({
                ...ctx,
                api: currentApi,
            })
        }

        return {
            pageProps,
            master: await master,
            language: language,
            apiCacheData: currentApi.extractCache(),
        }
    }

    componentDidMount() {
        api.fillCache(this.props.apiCacheData)
    }

    render() {
        const { Component, pageProps, router, master, language } = this.props

        return (
            <>
                <Head />
                <NextNProgress />
                <LocaleProvider masterLocale={language}>
                    <OrderProvider master={master?.data}>
                        <PageTransition
                            skipInitialTransition
                            timeout={300}
                            classNames="screen"
                            loadingDelay={300}
                            loadingTimeout={{
                                enter: 0,
                                exit: 0,
                            }}
                            loadingClassNames="loading-indicator"
                        >
                            <Page>
                                <Component {...pageProps} key={router.route} />
                            </Page>
                        </PageTransition>
                    </OrderProvider>
                </LocaleProvider>
            </>
        )
    }
}

export default MyApp
